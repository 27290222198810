'use client';

import {
  useMemo, useCallback, useRef, useState,
} from 'react';
import { Formik, FormikProps } from 'formik';
import cn from 'classnames';

import { Box } from 'uikitv2/Box';
import { Cards2 } from 'uikitv2/Cards2';
import { EmptyPlace } from 'uikitv2/EmptyPlace';
import { documentation } from 'common/links';
import { Title } from 'common/components/Title';
import { useAppSelector, useAppDispatch } from 'lib/hooks';
import { buildOrderFormSelector } from 'lib/features/createOrderV2/selectors';
import { isMobileSelector } from 'lib/features/windowSize';
import { useExtendedFormContent } from 'lib/features/createOrderV2/hooks/useExtendedFormContent';
import { DeleteContentProps, useContent } from 'lib/features/createOrderV2/hooks/useContent';
import { useQuickDeploy } from 'lib/features/createOrderV2/hooks/useQuickDeploy';
import { pageFiltersSelector, viewTypeSelector } from 'lib/features/shopwindow/selectors';
import {
  Blocks, FormControlPanel, FormDatasets, FormModels, ViewType,
} from 'lib/features/shopwindow/types';
import { updatePageFilters } from 'lib/features/shopwindow';
import { getPreparedForm } from 'lib/features/shopwindow/helpers';
import { useOnClickRow } from 'hooks/useOnClickRow';
import { FieldsBuildOrderForm } from 'lib/features/createOrderV2/types';
import { ControlPanel } from './ControlPanel';
import { Filters } from './Filters';
import {
  getValidationSchema, getColumns, getFields, INTERVAL, textNoFound,
} from './helpers';
import useOffers from './hooks/useOffers';
import classes from './Content.module.scss';
import { Form, ContentProps, FormFilters } from './types';
import Table from './Table/Table';
// import { Heading } from './Heading';

export const Content = ({ active, prefetch }: ContentProps) => {
  const isMobile = useAppSelector(isMobileSelector);
  const previousFormRef = useRef<string>('');
  const formikRef = useRef<FormikProps<FormModels | FormDatasets> | null>(null);
  const dispatch = useAppDispatch();
  const filters = useAppSelector(pageFiltersSelector(active));
  const buildOrderForm = useAppSelector(buildOrderFormSelector);
  const { loading: loadingExtending, extendedFormContent } = useExtendedFormContent(buildOrderForm);
  const { quickDeployCatched, loading: loadingQuickDeploy, quickDeleteContent } = useQuickDeploy(extendedFormContent);
  const viewType = useAppSelector(viewTypeSelector(active));
  const [isValidating, setIsValidating] = useState(false);
  const { onClickRow } = useOnClickRow(true);

  const {
    data, refetch, fetchNextPage: fetchNextPageOffers, isFetchingNextPage, pageInfo, pageData, isLoading,
  } = useOffers(prefetch?.data);

  const handleSubmitForm = useCallback((formikProps: FormikProps<Form>) => async (newValues?: Form | null) => {
    const { submitForm } = formikProps || {};
    const newFilters = formikRef.current?.values ?? newValues;
    // prevent refetch if equal filters
    if (!newFilters || previousFormRef.current === JSON.stringify(newFilters)) {
      return;
    }
    previousFormRef.current = JSON.stringify(newFilters);
    dispatch(updatePageFilters({ page: active, filters: newFilters }));
    // execute after main thread completed
    setTimeout(() => {
      submitForm();
      setIsValidating(true);
    }, INTERVAL);
  }, [active, dispatch]);

  const handleSubmitFilters = useCallback((formikProps: FormikProps<Form>) => async (newValues?: FormFilters) => {
    const { values: oldValues } = formikProps || {};
    handleSubmitForm(formikProps)(newValues ? { ...oldValues, [Blocks.filters]: newValues } : oldValues);
  }, [handleSubmitForm]);

  const handleSubmitControlPanel = useCallback((formikProps: FormikProps<Form>) => async (newValues?: FormControlPanel) => {
    const { values: oldValues } = formikProps || {};
    handleSubmitForm(formikProps)(newValues ? { ...oldValues, [Blocks.controlPanel]: newValues } : oldValues);
  }, [handleSubmitForm]);

  const onSubmitForm = useCallback((form: Form) => {
    refetch(getPreparedForm(active, form, extendedFormContent));
  }, [refetch, extendedFormContent, active]);

  const fetchNextPage = useCallback(({ values }) => () => {
    fetchNextPageOffers(getPreparedForm(active, values, extendedFormContent));
  }, [fetchNextPageOffers, extendedFormContent, active]);

  const validationSchema = useMemo(() => getValidationSchema(), []);

  const onDeleteContent = useCallback((props: DeleteContentProps) => {
    quickDeleteContent({ field: props.field });
  }, [quickDeleteContent]);

  const columns = useMemo(
    () => getColumns({
      onDeleteContent,
      quickDeploy: quickDeployCatched,
      extendedFormContent,
      loading: loadingExtending || loadingQuickDeploy,
      isMobile,
      active,
    }),
    [
      onDeleteContent,
      extendedFormContent,
      loadingExtending,
      loadingQuickDeploy,
      isMobile,
      active,
      quickDeployCatched,
    ],
  );

  const fields = useMemo(() => (
    getFields({
      quickDeploy: quickDeployCatched,
      onDeleteContent,
      extendedFormContent,
      loading: loadingExtending || loadingQuickDeploy,
      active,
    })
  ), [extendedFormContent, loadingExtending, quickDeployCatched, onDeleteContent, active, loadingQuickDeploy]);

  const classNames = useMemo(() => ({
    tr: classes.tr,
  }), []);

  const getRowId = useCallback((data) => {
    const { original } = data;
    return original?.id || original?.teeOffer?.id;
  }, []);

  const isShowCards = viewType === ViewType.cards || isMobile;

  const loading = useMemo(() => (isLoading || isFetchingNextPage), [isLoading, isFetchingNextPage]);

  return (
    <Formik
      validateOnChange={isValidating}
      validateOnBlur={isValidating}
      initialValues={filters}
      onSubmit={onSubmitForm}
      enableReinitialize
      validationSchema={validationSchema}
      innerRef={formikRef}
    >
      {(formikProps) => {
        return (
          <Box className={classes.container}>
            <Box direction="column">
              <Title classNameWrap={classes['wrap-title']} title={active as string} href={documentation.markeplace} />
              <Filters
                className={classes.filters}
                name={Blocks.filters}
                active={active}
                onSubmit={handleSubmitFilters(formikProps)}
                interval={INTERVAL}
              />
            </Box>
            <Box direction="column" className={classes.content}>
              <ControlPanel
                name={Blocks.controlPanel}
                columns={columns}
                active={active}
                filtersName={Blocks.filters}
                onSubmit={handleSubmitControlPanel(formikProps)}
                showCount={data?.length}
                totalCount={pageData?.count}
                interval={INTERVAL}
              />
              {data?.length === 0
                ? (
                  <EmptyPlace
                    spinnerWrapClassName={cn(classes['empty-place'], { [classes['empty-place-not-loading']]: !loading })}
                    text={!loading ? textNoFound : undefined}
                  />
                ) : isShowCards
                  ? (
                    <Cards2
                      fields={fields}
                      data={data}
                      onClickCard={onClickRow}
                      fetchNextPage={fetchNextPage(formikProps)}
                      hasNextPage={pageInfo?.hasNextPage}
                      isFetchingNextPage={isFetchingNextPage}
                    />
                  ) : (
                    <Table
                      columns={columns}
                      active={active}
                      data={data}
                      fetchNextPage={fetchNextPage(formikProps)}
                      hasNextPage={pageInfo?.hasNextPage}
                      isFetchingNextPage={isFetchingNextPage}
                      onClickRow={onClickRow}
                      classNames={classNames}
                      getRowId={getRowId}
                      classNameContainer={classes['table-container']}
                    />
                  )}
            </Box>
          </Box>
        );
      }}
    </Formik>
  );
};
